import Axios from "axios";

export const getWhitelabelDomainData = async (url: string) => {
  try {
    const {
      REACT_APP_API_SERVER_BASE_URL = "",
      REACT_APP_GET_WHITELABEL_DOMAIN_DATA = "",
    } = process.env;

    const { data } = await Axios.post(
      `${REACT_APP_API_SERVER_BASE_URL}${REACT_APP_GET_WHITELABEL_DOMAIN_DATA}`,
      {
        url,
      }
    );
    console.log(data, "domain url data");
    if (data.document) {
      return {
        businessName: data?.document?.businessName,
        logo: data?.document?.logo,
        customDomainId: data?.document?.customDomainId,
        domainName: data?.document?.domainName,
      };
    } else {
      return false;
    }
  } catch (error) {
    console.log(error, "domain data error");
    return false;
  }
};
