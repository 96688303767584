/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import "./styles.css";
import { getWhitelabelDomainData } from "../../functions/global";
import { getUrl } from "./util";

const Home = () => {
  const [refId, setRefId] = useState<string | null>();
  const [domainRef, setDomainRef] = useState<string | null>("");
  const [access, setAccess] = useState<string | null>("");
  const [domainId, setDomainId] = useState<string | null>("");
  const [email, setEmail] = useState<string | null>("");
  const [domainName, setDomainName] = useState<string | null>("");

  useEffect(() => {
    if (window.location.search) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const ref_id = params.get("ref_id");
      const domain_ref = params.get("domain_ref");
      const access = params.get("access");
      const email = params.get("email");
      setRefId(ref_id);
      setDomainRef(domain_ref);
      setAccess(access);
      setEmail(email);
    }

    if (window.location.host) {
      handleUpdateFavIcon(window.location.host);


      // Reload changes

      if (PerformanceObserver) {
        const observer = new PerformanceObserver((list) => {
          const entries = list.getEntries();

          entries.forEach((entry: any) => {
            if (entry?.name?.split('.')?.[0].includes('ad')) {

              // Example check for CPU or bandwidth heavy usage
              if (entry.duration > 60 || entry.transferSize > 4 * 1024 * 1024) {
                // CPU > 60ms or 4MB+
                let reloaded = sessionStorage.getItem("reloaded");

                if (!reloaded) {
                  sessionStorage.setItem("reloaded", "false");
                }

                reloaded = sessionStorage.getItem("reloaded");
                switch (reloaded) {
                  case "false": {
                    sessionStorage.setItem("reloaded", "true");
                    window.location.reload();
                    break;
                  }
                  case "true":
                    observer.disconnect();
                    break;

                  default:
                    break;
                }
              }
            }
          });
        });

        observer.observe({ entryTypes: ["resource"] });
      }
    }

  }, [window.location.search]);

  const handleUpdateFavIcon = async (url: string) => {
    const domainData = (await getWhitelabelDomainData(url)) as any;
    const r = document.querySelector(":root") as any;
    r.style.setProperty("--color-primary", "black");
    r.style.setProperty("--app-drawer-background-color", "black");

    setDomainId(domainData?.customDomainId || "");
    setDomainName(domainData?.domainName || "");

    document.title = domainData?.businessName || "AI Ads";
    const link = document.querySelector("link[rel~='icon']") as any;
    if (link) {
      link.href = domainData?.logo;
    }
  };

  return (
    <div className="home-iframe-main-container">
      <iframe
        src={getUrl({
          refId,
          domainRef,
          access,
          domainId,
          email,
          domainName,
        })}
        title={"marketing"}
        className="plai-i-frame"
        id="plai-iframe"
        allow="clipboard-read; clipboard-write"
      />
    </div>
  );
};

export default Home;
