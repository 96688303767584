const getBlackLabelDomain = (
  domain: string,
  arg: {
    refId: string | null | undefined;
    domainRef: string | null;
    access: string | null;
    email: string | null;
  }
): string => {
  const { refId, domainRef, access, email } = arg;
  const params = [];
  if (domainRef) {
    params.push(`ref_id=${refId}`);
    if (access) {
      params.push(`access=${access}`);
    }
    params.push(`domain_ref=${domainRef}`);
  } else if (refId) {
    params.push(`ref_id=${refId}`);
  } else if (access) {
    params.push(`access=${access}`);
    params.push(`email=${email}`);
  }

  if (params.length > 0) {
    return `${domain}/?${params.join("&")}`;
  } else {
    return `${domain}`;
  }
};

const getWhiteLabelDomain = (
  domain: string,
  arg: {
    refId: string | null | undefined;
    access: string | null;
    email: string | null;
  }
): string => {
  const { refId, access, email } = arg;
  if (access) {
    return `${domain}/?access=${access}&email=${email}`;
  } else if (refId) {
    return `${domain}/?ref_id=${refId}`;
  }
  return `${domain}`;
};

const getWhiteLabelBaseDomain = (domainName: string) => {
  if (["test.plai.marketing"].includes(domainName)) {
    return "https://web-app-beta-yifgeucula-uc.a.run.app";
  }
  return "https://whitelabel-agency.firebaseapp.com";
};

export const getUrl = (arg: {
  refId: string | null | undefined;
  domainRef: string | null;
  access: string | null;
  domainId: string | null;
  email: string | null;
  domainName: string | null;
}): string => {
  const { domainRef, domainId, domainName } = arg;
  // use domainName and change different type of domain
  const BLACK_LABEL_DOMAIN = "https://blacklabel-agency.firebaseapp.com";
  const WHITE_LABEL_DOMAIN = getWhiteLabelBaseDomain(domainName ?? "");
  const isBlackLabel = domainRef || domainId;
  if (isBlackLabel) {
    return getBlackLabelDomain(BLACK_LABEL_DOMAIN, arg);
  }
  return getWhiteLabelDomain(WHITE_LABEL_DOMAIN, arg);
};
